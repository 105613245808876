@import '~antd/dist/antd.css';
@import './css/Feed.css';
@import './css/Login.css';
@import './css/Register.css';
@import './css/Artist.css';
@import './css/Header.css';
@import './css/Account.css';
@import './css/Fan.css';

:root {
  --weverseGreen: #84FAB0;
  --weverseBlue: #8FD3F4;
  --weversePurple:#9E90F2;
  --weverseBlack:#2E2E2E;
  --weverseGrey:#c4c4c4;
}

.text-white{
  font-family: 'Roboto Mono', monospace;
  color:white;
}
.text-grey{
  font-family: 'Roboto Mono', monospace;
  color:var(--weverseGrey);
}
.text-purple{
  font-family: 'Roboto Mono', monospace;
  color:var(--weversePurple);
}

.btn-hover-white{
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
}
.btn-hover-white:hover{
  border-style: solid;
  border-width: 1px;
  border-color: white;
}
.btn-hover-purple{
  background-color: rgba(158,144,242,0);
}
.btn-hover-purple:hover{
  background-color: rgba(158,144,242,0.2);
}

body{
  background-color: var(--weverseBlack);
}

/* html,
body {
    overflow: hidden;
    position: relative;
    height: 100%;
    width:100%;
} */

/* RWD Container */
.container {
    width: 100vw; 
    display: flex;
    align-self: center;
    margin: auto;
    background-color: var(--weverseBlack);
}

/* sm */
@media (min-width: 576px) { 
  
} 

/* md */
@media (min-width: 768px) {
  
} 

/* lg */
@media (min-width: 992px) {
  
} 

/* xl */
@media (min-width: 1200px) {
  
} 

/* xxl */
@media (min-width: 1600px) {
  
} 

.main-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width:100vw;
}

.layout-header,
.layout-footer {
  padding: 0;
  height: auto;
  background-color: var(--weverseBlack);
}

.layout-content{
  padding: 0;
  height: auto;
  background-color: var(--weverseBlack);
  
  display: flex;
  flex-direction: row;
}

.layout-content-gradient{
  display: flex;
  flex-direction: row;
  background: linear-gradient(180deg, rgba(132,250,176,0.20351890756302526) 30%, rgba(143,211,244,0.2147233893557423) 84%);
}

/* header */
.header {
  display: flex;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  background-color: var(--weverseBlack);
  height:70px;
  line-height: 70px;
  border-bottom: white;
  border-style: solid;
  border-width: 0px;
}

.header-wrap-left,.header-wrap-right,.header-wrap-middle {
  display: flex;
  flex-direction: row;
  padding-top: 0rem;
  position: relative;
}

.header-wrap-left{
  width:20%;
}
.header-wrap-right{
  width:20%;
  padding-right:2rem;
  justify-content: flex-end;
}
.header-wrap-middle{
  font-family: 'Nova Round', cursive;
  font-size: 36px;
  background: linear-gradient(90deg, var(--weverseGreen) 20%, var(--weverseBlue) 84%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.hr-header-line {
  width: 100;
  border:0;
  height: 1px;
  background-color: white;
  opacity: 1;
  margin: 0;
}

.header-cart-summary {
  /* position: absolute; */
  /* bottom: -15px;
  right: 1rem; */
  
  /* background-color: aquamarine; */
  display:flex;
  margin:1.2rem 0rem;
  height:30px;
  pointer-events: none;
}

.icon-user{
  display:block;
}

@media (max-width: 768px){
  .icon-user{
    display:none;
  }
  .header-wrap-right{
    width:20%;
    padding-right:1.5rem;
    justify-content: flex-end;
  }
}





/* icon */
.anticon, .anticon-close ,.ant-modal-close-icon{
  color:white
}


.header-icon{
  height:70px;
  display: flex;
  align-items: center;
  font-size: 24px;
  margin-left:2rem;
  color:var(--weverseBlue);
  /* margin-right:2rem; */
  /* color: black; */
}
.header-icon:hover{
  height:70px;
  display: flex;
  align-items: center;
  font-size: 24px;
  margin-left:2rem;
  color:var(--weverseGreen);
  /* margin-right:2rem; */
  /* color: black; */
}

.header-icon a{
  color:var(--weverseBlue)
}
.header-icon .ant-icon:hover{
  color:var(--weverseGreen)
}
.header-icon .ant-icon:active{
  color:var(--weverseBlue)
}

.header-wrap-right a{
  color:var(--weverseBlue)
}
.header  a:hover {
  color: var(--weverseGreen);
}
.header  a:active {
  color: var(--weverseBlue);
}
.navnar-pagelist a{
  color:white;
}
.navnar-pagelist  a:hover {
  color: var(--weversePurple);
}
.navnar-pagelist  a:active {
  color: white;
}
.cart-check a{
  color:white
}
.cart-check  a:hover {
  color: white
}
.cart-check  a:active {
  color: white
}

.header-cart-summary .header-icon{
  height:30px;
  display: flex;
  align-items: center;
  font-size: 24px;
  margin-left:2rem;
  color:var(--weverseBlue);
  pointer-events: all;
  cursor: pointer;
  /* margin-right:2rem; */
  /* color: black; */
}
.header-cart-summary .header-icon:hover{
  color:var(--weverseGreen);
  pointer-events: all;
  cursor: pointer;
  /* margin-right:2rem; */
  /* color: black; */
}

@media (max-width: 768px){
  .header-icon{
    height:70px;
    display: flex;
    align-items: center;
    font-size: 24px;
    margin-left:1.5rem;
    color:var(--weverseBlue);
    /* margin-right:2rem; */
    /* color: black; */
  }
  .header-icon:hover{
    /* height:70px; */
    display: flex;
    align-items: center;
    font-size: 24px;
    margin-left:1.5rem;
    color:var(--weverseGreen);
    /* margin-right:2rem; */
    /* color: black; */
  }
}



/* Navbar */

.Navbar{
  height:100%;
  width:0rem;
  padding:1.5rem;
  overflow-y: auto;
  border:0;
  border-color: white;
  border-right-width:1px;
  border-style: solid;
  opacity: 1;
  display: block;
}

.Navbar.active {
  width :0;
  overflow: hidden;
  transition: 350ms;
}

.navnar-pagelist{
  padding-left:1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navbar-pagelist-text{
  font-family: 'Roboto Mono', monospace;
  font-size: 16px;
  line-height: 28px;
}






.navbar-line{
  height:0.1px;
  min-height: 0.1px;
  width:100%;
  background-color: white;
  margin-top:2rem;
  margin-bottom: 2rem;
}

.navbar-artistlist{
  width:100%;
  padding-left:0rem;
}

.navbar-artistlist-text{
  font-family: 'Roboto Mono', monospace;
  font-size: 16px;
  color:white;
}

.navbar-artistlist-topic{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding:0.5rem;
  padding-left: 1rem;
}

.navbar-artistname-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding:0.5rem;
  margin:0.5rem;
  padding-left: 1rem;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  cursor: pointer;
}
.navbar-artistname-container:hover{
  background-color: rgba(158, 144, 242, 0.2); 
}
.navbar-artistname-container-inactive{
  background-color: rgba(158, 144, 242, 0); 
}
.navbar-artistname-container-active{
  background-color: rgba(158, 144, 242, 0.2); 
}

.navbar-artistlist-thumbnail{
  width:25px;
  height:25px;
  border-radius: 25px;
  
  margin-right: 0.5rem;
}

.navbar-footer{
  display: flex;
  justify-content: center;
  
}

.navbar-footer-text{
  font-family: 'Roboto Mono', monospace;
  font-size: 12px;
  color:white;
}

.Navbar-phone{
  display:none;
  
}




/*手機選單*/
@media (max-width: 768px){
  .Navbar{
    display:none;
  }
  .Navbar-phone{
    display:block;
    position: fixed;
  background-color: var(--weverseBlack);
  width:100vw;
  /* height:20rem; */
  z-index: 3;
  margin-top: 70px; 
  
  /* padding-top: 70px; */
  /* padding-bottom: 100px; */
  overflow: auto;
  }
  
  
  .Navbar.active {
    width :0;
    overflow: hidden;
    transition: 350ms;
  }
  
  .navnar-pagelist{
    padding-left:1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .navbar-pagelist-text{
    font-family: 'Roboto Mono', monospace;
    font-size: 16px;
    line-height: 28px;
  }
  
  .navbar-line{
    height:0.1px;
    min-height: 0.1px;
    width:100%;
    background-color: white;
    margin-top:2rem;
    margin-bottom: 2rem;
  }
  
  .navbar-artistlist{
    width:100%;
    padding-left:0rem;
  }
  
  .navbar-artistlist-text{
    font-family: 'Roboto Mono', monospace;
    font-size: 16px;
    color:white;
  }
  
  .navbar-artistlist-topic{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding:0.5rem;
    padding-left: 1rem;
  }
  
  .navbar-artistname-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding:0.5rem;
    margin:0.5rem;
    padding-left: 1rem;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    cursor: pointer;
  }
  .navbar-artistname-container:hover{
    background-color: rgba(158, 144, 242, 0.2); 
  }
  
  .navbar-artistlist-thumbnail{
    width:25px;
    height:25px;
    border-radius: 25px;
    
    margin-right: 0.5rem;
  }
  
  .navbar-footer{
    display: flex;
    justify-content: center;
    
  }
  
  .navbar-footer-text{
    font-family: 'Roboto Mono', monospace;
    font-size: 12px;
    color:white;
  }
}

/* main Area */

.mainarea{
  height:100%;
  width:100%;
  padding:7rem;
  padding-top: 0rem;
  overflow: auto;
  overflow: scroll;
}

.mainarea-topic-text{
  font-family: 'Roboto Mono', monospace;
  font-size: 24px;
  color:white;
  padding-top:1.5rem;
  padding-bottom:1.5rem;
}

.mainarea-artist-text{
  font-family: 'Roboto Mono', monospace;
  font-size: 18px;
  color:white;
  padding-top:1.5rem;
  padding-bottom:1.5rem;
}

.mainarea-bar-container{
  display: flex;
  justify-content: space-between;
}

/* select */

.mainarea-bar-container .ant-select:not(.ant-select-customize-input) .ant-select-selector{
  background-color: white;
  border-radius: 10px;
  font-family: 'Roboto Mono', monospace;
}

.mainarea-bar-container .ant-select-arrow{
  color:black;
}

.mainarea-bar-container .ant-btn{
  border:0;
}
.mainarea-bar-container .ant-input-affix-wrapper{
  border-color:white;
  
}

.mainarea .ant-select:not(.ant-select-customize-input) .ant-select-selector{
  background-color: transparent;
  border-radius: 10px;
  font-family: 'Roboto Mono', monospace;
  color:white;
}

.mainarea .ant-select-arrow{
  color:white;
}

.mainarea .ant-btn{
  border:0;
}
.mainarea .ant-input-affix-wrapper{
  border-color:white;
  
}
.product-qty .ant-select:not(.ant-select-customize-input) .ant-select-selector{
  background-color: transparent;
  border-radius: 10px;
  height:25px;
  font-family: 'Roboto Mono', monospace;
  display: flex;
  text-align: center;
  color:var(--weverseGrey);
}
.product-qty .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
  height:25px;
  display: flex;
  text-align: center;
}

.product-qty .ant-select-selection-search{
 line-height: 25px;
 
 
}

.product-qty .ant-select-selection-item{
  top:-4px;
}

.product-qty .ant-select-single .ant-select-selector .ant-select-selection-search{
  display: flex;
  text-align: center;
}

.product-qty .ant-select-arrow{
  color:var(--weverseGrey);
}

.product-qty .ant-btn{
  border:0;
}
.product-qty .ant-input-affix-wrapper{
  border-color:var(--weverseGrey);
  
}

/* search */
.mainarea-bar-container .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 10px; 
  border-bottom-left-radius: 10px;
}

.mainarea-bar-container .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0 100px 100px 0;
}

.mainarea-bar-container .ant-input-group-addon{
  border-radius: 10px;
}

.mainarea-bar-container{
  font-family: 'Roboto Mono', monospace;
}

.mainarea-productlist-container{
  height:80%;
  overflow: auto;
}

.mainarea-product{
  border-radius: 10px;
  border:0;
  background-color: transparent;
  /* background-color: coral; */
  display: flex;
  text-align: start;
}

.product-name{
  font-family: 'Roboto Mono', monospace;
  font-size: 14px;
  color:white;
  margin:1rem 0rem 0rem 0rem;
}
.product-price{
  font-family: 'Roboto Mono', monospace;
  font-size: 12px;
  color:rgb(185, 185, 185);
  margin:1rem 0rem 0rem 0rem;
}

/* productdetail */
.productdetail-card-area{
  display: flex;
  justify-content: center;
  /* background-color: coral; */
  width:100%;
  height:31rem;
  position: relative;
}
.productdetail-card{
  width:65rem;
  height:28rem;
  position: absolute;
  z-index: 1;
  pointer-events: none;
  background-color: transparent;
}

.productdetail-card1,.productdetail-card2,.productdetail-card1-back,.productdetail-card2-back{
  /* width:50%; */
  width:62rem;
  height:28rem;
  border-radius: 10px;
  position: absolute;
  
}

.ant-card-body{
  padding:0;
}




.productdetail-card1{
  background-color: var(--weverseBlack);
  border-style: solid;
  border-color:white;
  border-width:1px;
  margin-top: 1.5rem;
  margin-right: 0rem;
  pointer-events: all;
  display: flex;
  flex-direction: row;
  padding: 7% 5%;
}

.productdetail-card2{
  
  /* background-color: goldenrod; */
  background: linear-gradient(90deg, var(--weverseGreen) 20%, var(--weverseBlue) 84%);
  opacity: 40%;
  margin-left: 3rem;
  pointer-events: none;
}
.productdetail-card1-back{
  /* background-color: goldenrod; */
  background: linear-gradient(90deg, var(--weverseGreen) 20%, var(--weverseBlue) 84%);
  opacity: 40%;
  margin-top: 1.5rem;
  margin-right: 0rem;
  pointer-events: none;
}

.productdetail-card2-back{
  
  
  background-color: var(--weverseBlack);
  border-style: solid;
  border-color:white;
  border-width:1px;
  margin-left: 3rem;
  pointer-events: all;
  display: flex;
  flex-direction: row;
  padding: 7% 5%;
}

.productdetail-button-flip{
  margin:1rem 0rem;
  width:8rem;
  color:var(--weversePurple);
  cursor: pointer;
}

.productdetail-card1-left,.productdetail-card1-middle,.productdetail-card1-right{
  
  height:100%;
  width:100%;
  overflow: auto;
}

.productdetail-card2-left{
  height:100%;
  width:60%;
  /* background-color: lightseagreen; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.productdetail-card2-right{
  height:100%;
  width:40%;
  /* background-color: blueviolet; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.productdetail-card1-left{
  /* background-color: coral; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.productdetail-card1-middle{
  /* background-color: palevioletred; */
  display: flex;
  align-items: center;
}
.productdetail-card1-right{
  /* background-color: rebeccapurple; */
}

.productdetail-card1-right{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.productdetail-card1-name{
  font-size: 16px;
  margin:1rem 0rem;
}

.productdetail-card1-price{
  font-size: 20px;
}

.productdetail-card1-type{
  font-size: 16px;
  margin:0.5rem 0rem;
}
.productdetail-card1-overview{
  font-size: 12px;
  height:32%;
  overflow: auto;
}

.product-type-imagecon{
  display: flex;
  justify-content: space-between;
}

.product-type-image{
  width:30%;
  cursor: pointer;
  border-style:solid;
  border-width: 1px;
  border-radius: 10px;
  border-color:var(--weversePurple);
  background-color: rgba(158,144,242,0);
}
.product-type-image-opa{
  width:30%;
  cursor: pointer;
  border-style:solid;
  border-width: 1px;
  border-radius: 10px;
  border-color:var(--weversePurple);
  background-color: rgba(158,144,242,0.2);
}

.product-type-image:hover{
  background-color: rgba(158,144,242,0.2);
}



.product-image{
  width:80%;
  margin: auto;
}

.card {
  width: 100%;
  height: 80%;
  /* background-color: #84FAB0; */
  border-radius: 5px;background-size: cover;
  background-position: center center;
  will-change: transform;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card2 {
  width: 80%;
  height: 80%;
  will-change: transform;
  display: flex;
  justify-content: center;
}

.productdetail-card1-btn{
  background-color: var(--weversePurple);
  width:60%;
  margin-left: 40%;
  height:2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}

.productdetail-card2-info{
  height:70%;
  overflow: auto;
  margin-right: 0.5rem;
  /* background-color: #9E90F2; */
}
.productdetail-card2-left .productdetail-button-flip{
  margin:1rem 0rem 0rem 0rem;
  width:6rem;
  color:var(--weversePurple);
  cursor: pointer;
}

.productdetail-card2-name{
  font-size:18px;
  margin-bottom:1rem;
}

.productdetail-card2-info-topic{
  font-size:14px;
  margin:0rem 0rem 0.5rem 0rem;
}
.productdetail-card2-info-text{
  font-size:12px;
  margin:0rem 0rem 1rem 0rem;
}


/*cart*/

.cart-modal{
  background-color: bisque;
  padding: 0;
  width:20rem;
}

.cart-item{
  display: flex;
  flex-direction: row;
  /* background-color: purple; */
  margin-bottom: 0.5rem;
  align-items: center;
}

.cart-image-con{
  width:5rem;
}
.cart-image{
  width:5rem;
}

.cart-detail-con{
  display: flex;
  flex-direction: row;
}

.cart-item-content{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ant-modal-header{
  display:none;
}
.ant-modal-body,.ant-modal-content{
  background-color: var(--weverseBlack);
  width:auto;
  /* height:auto; */
  padding-top: 1.5rem;
}



.cart-name{
  font-size: 14px;
}

.cart-item-content .text-grey{
  font-size: 12px;
  margin-right:0.2rem;
  margin-top:0.1rem;
}

.cart-delete{
  cursor: pointer;
  width:20px;
  height:20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart-delete:hover{
  background-color: rgba(255,255,255,0.2);
  border-radius: 10px;
}

.cart-total-price-wrap{
  display: flex;

}

.cart-check{
  background-color: var(--weversePurple);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding:0rem 1rem;
  height:2rem;
  margin-left: 56%;
  border-radius: 10px;
  cursor: pointer;
  
}

/*Check Out*/
.checkout{
  width:100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.checkout-card-con{
  width:80%;
  height:40rem;
  display: flex;
  justify-content: center;
  /* background-color: coral; */
  width:100%;
  position: relative;
  overflow: hidden;
  
}

.checkout-card1,.checkout-card2,.checkout-card3,.checkout-card-gradient{
  width:80%;
  height:37rem; 
  position: absolute;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(255,255,255,1);
  overflow: auto;
}

.checkout-card1{
  background-color: var(--weverseBlack);
  /* background-color: #84FAB0; */
  margin-left: 0rem;
  margin-top: 3rem;
}

.checkout-card-gradient{
  background: linear-gradient(90deg, var(--weverseGreen) 20%, var(--weverseBlue) 84%);
  opacity: 40%;
  border-width: 0px;
}
.checkout-card2{
  background-color: var(--weverseBlack);
  /* background-color: khaki; */
  margin-left: 5rem;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
}
.checkout-card3{
  background-color: var(--weverseBlack);
  /* background-color: lightcoral; */
  margin-left: 10rem;
  margin-top: 0rem;
}

.checkout-bigtitle{
  margin: 1rem 3rem;
  font-size: 16px;
}

.checkout-title{
  margin: 0.5rem 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
}

.checkout .cart-item{
  margin: 0.5rem 4rem;
  border-bottom: 1px solid white;
  /* background-color: blueviolet; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.checkout .cart-image-con{
  /* background-color: #84FAB0; */
  margin:0rem 2.5rem;
  width:40%;
}
.checkout .cart-image{
  width:5rem;
}
.checkout .cart-type{
  /* background-color: #84FAB0; */
  width:4rem
}
.checkout .cart-name{
  width:40%;
  /* background-color: orange; */
}
.checkout .product-qty{
  /* background-color: orange; */
  width:3.5rem
}
.checkout .cart-price{
  /* background-color: orange; */
  width:5rem
}

.checkout .checkout-list-con{
  /* background-color: #8FD3F4; */
  height:15rem;
  
  overflow: auto;
}

.checkout-summary-con{
  margin-left: 4rem;;
  /* background-color: #9E90F2; */
  width:40%;
}
.checkout-subtotal{
  display: flex;
  flex-direction: row;
  margin:1rem 0rem;
}
.checkout-total{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: solid 1px white;
  padding:1rem 0rem;
}
.checkout-btn-con{
  display: flex;
  justify-content: flex-end;
  margin-right: 4rem;
  
}
.checkout-card2 .checkout-btn-con{
  width:100%;
}

.checkout-next-btn1{
  margin:0rem;
  margin-left:2rem;
  margin-bottom:1rem;
  width:8rem;
  height:2.5rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9E90F2;
  cursor: pointer;
}

.checkout-back-btn1{
  margin:0rem;
  width:8rem;
  height:2.5rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color:var(--weversePurple);
  border:1px solid var(--weversePurple)
}


/*small list 小清單*/
.smallList{
  /* margin-top:10rem; */
  /* background-color: purple; */
  width: 100%;
}

.smallList-notopen{
  border-radius: 10px;
  margin: 1rem 1rem;
  border: solid 1px white;
  /* background-color: #84FAB0; */
}

.smallList-open{
  height:0vh;
  overflow: auto;
}
.smallList-top{

}

.smallList1{
  /* background-color: #84FAB0; */
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}


/* .smallList .checkout-list-con{
  height:0rem;
  
  overflow: hidden;
}

.smallList .checkout-title{
  overflow: hidden;
  height: 0;
}
.smallList .checkout-bigtitle{
  overflow: hidden;
  height: 0;
}

.smallList .checkout-summary-con{
  height:0rem;
  overflow: hidden;
} */

.smallList-notopen-con{
  display: flex;
  /* background-color: coral; */
  flex-direction: row;
  margin: 0rem 0rem;
  margin-right:0rem;
  padding: 0rem 0rem 0rem 1rem;
}

.smallList-notopen-con .checkout-bigtitle{
  
  margin: 0.2rem 0rem;
  font-size: 14px;
}
.smallList-notopen-con .checkout-bigtitle2{
 
  margin: 0.2rem 0rem;
  font-size: 14px;
}

.smallList-notopen-btn{
  margin-right: 1rem;
  cursor: pointer;
}

.smallList-input-text{
  font-size: 14px;
  margin:0.5rem 0rem 0.2rem 0rem;
}
.smallList-bottom{
  display: flex;
  flex-direction: row;
}

.smallList-left,.smallList-right{
  width:45%;
  margin:0 1rem;
  /* background-color: #9E90F2; */
  border:solid white 1px;
  border-radius: 10px;
  padding:0 1rem;
  height:25rem;
  margin-bottom: 1rem;
}


.smallList-right .ant-input,.smallList-left .ant-input{
  background-color: transparent;
  width:100%;
  border-radius: 10px;
  font-family: 'Roboto Mono', monospace;
  color:white;
  margin-bottom: 0rem;
}

.carousel-artists{
  width:100%;
  height:auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.carousel-artists-img-con{
  width:30%;
  height:auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.carousel-artists-name{
  font-size: 18px;
  width:100%;
  height:5rem;
  text-align: left;
  padding-left: 1rem;
  padding-top: 0.5rem;
}
.carousel-newContent .carousel-artists{
  width:100%;
  height:auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.carousel-newContent .carousel-artists-img-con{
  width:100%;
  height:25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}







/*-------RWDRWDRWD-----------------------------o-o-o--o-o-o-o-o-*/

@media (max-width: 1100px) {
  .productdetail-card-area{
    height:26.5rem;
  }
  .productdetail-card{
    width:45rem;
    height:25rem;
  }
  
  .productdetail-card1,.productdetail-card2,.productdetail-card1-back,.productdetail-card2-back{
    /* width:50%; */
    width:42rem;
    height:25rem;
    
  }
  .card {
    width: 100%;
    height: 50%;
    border-radius: 5px;background-size: cover;
    background-position: center center;
    will-change: transform;
    display: flex;
    justify-content: center;
  }
  .card2 {
    width: 80%;
    height: 80%;
    will-change: transform;
    display: flex;
    justify-content: center;
  }
  .product-image{
    width:100%;
    margin: auto;
  }

  
  
} 
.checkout .cart-item-phone{
  display:none;
}





/*手機 結帳頁*/
@media (max-width: 768px) {
  
  
  .checkout-card-con{
    /* background-color: lightgoldenrodyellow; */
    height:80vh;
   
    justify-content: flex-start;
  }
  .checkout-card1,.checkout-card2,.checkout-card3,.checkout-card-gradient{
    width:90%;
    height:65vh; 
    position: absolute;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(255,255,255,1);
    overflow: auto;
  }
  .checkout-bigtitle{
    margin: 0.5rem 1rem;
    font-size: 16px;
  }
  .checkout-phonelist-middle{
    /* background-color: coral; */
    width:70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .checkout-phonelist-middlebottom{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height:auto;
  }
  
  .checkout-title{
    
    margin: 0.5rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    display: none;
  }
  
  .checkout .cart-item-phone{
    margin: 0.5rem 2rem;
    border-bottom: 1px solid white;
    /* background-color: blueviolet; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .checkout .cart-item{
    display:none;
  }
  
  .checkout .cart-image-con{
    /* background-color: #84FAB0; */
    margin:0rem 2.5rem;
    width:40%;
  }
  .checkout .cart-image{
    width:4rem;
    margin:0rem 0.5rem 0rem 0rem ;
  }
  .checkout .cart-type{
    /* background-color: #84FAB0; */
    width:3rem;
    font-size: 12px;
  }
  .checkout .cart-name{
    width:100%;
    font-size: 12px;
    /* background-color: orange; */
  }
  .checkout .product-qty{
    /* background-color: orange; */
    width:3rem;
    display:flex;
    flex-direction: row;
  }
  .checkout .cart-price{
    /* background-color: orange; */
    width:3rem;
    font-size: 12px;
  }
  
  .checkout .checkout-list-con{
    /* background-color: #8FD3F4; */
    height:35vh;
    
    overflow: auto;
  }
  
  .checkout-summary-con{
    margin: 0;
    padding:0rem 1rem;
    /* background-color: #9E90F2; */
    width:100%;
  }
  .checkout-subtotal{
    display: flex;
    flex-direction: row;
    margin:0.5rem 0rem;
  }
  .checkout-total{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-top: solid 1px white;
    padding:0.5rem 0rem;
  }
  .checkout-btn-con{
    display: flex;
    justify-content: space-around;
    width:100%;
    padding:0rem 0.8rem;
    margin-top: 0.5rem;
    /* background-color: #84FAB0; */
  }
  
  .checkout-next-btn1{
    margin:0rem;
    margin-left:1rem;
    margin-bottom:1rem;
    width:7rem;
    height:2.5rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #9E90F2;
    cursor: pointer;
    font-size: 12px;
  }
  
  .checkout-back-btn1{
    margin:0rem;
    width:7rem;
    height:2.5rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color:var(--weversePurple);
    border:1px solid var(--weversePurple);
    font-size: 12px;
  }

  .phone-list-detail{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.2rem;
  }
  .checkout-card2{
    background-color: var(--weverseBlack);
    /* background-color: khaki; */
    margin-left: 5rem;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items:stretch;
    justify-content: flex-start;
  }

  .smallList-bottom{
    display: flex;
    flex-direction: column;
    width:100%;
    padding:0 1rem;
    /* background-color: #8FD3F4; */
  }
  
  .smallList-left,.smallList-right{
    width:100%;
    margin: 0;
    /* background-color: #9E90F2; */
    border:solid white 1px;
    border-radius: 10px;
    padding:0 1rem;
    padding-bottom: 1rem;
    height:auto;
    margin-bottom: 1rem;
  }
  
  
} 
.productdetail-card-phone{
  display: none;
}
/*productdetailRWD*/
@media (max-width: 768px){
  .mainarea{
    height:100%;
    width:100%;
    padding:1rem;
    padding-top: 0rem;
    overflow: auto;
  }
  
  .mainarea-topic-text{
    font-family: 'Roboto Mono', monospace;
    font-size: 24px;
    color:white;
    padding-top:1.5rem;
    padding-bottom:1.5rem;
  }
  
  .mainarea-artist-text{
    font-family: 'Roboto Mono', monospace;
    font-size: 18px;
    color:white;
    padding-top:1.5rem;
    padding-bottom:1.5rem;
  }
  
  .mainarea-bar-container{
    display: flex;
    justify-content: space-between;
  }
  .productdetail-card-area{
    display: flex;
    justify-content: center;
    /* background-color: coral; */
    width:80vw;
    height:31rem;
    position: relative;
  }
  .productdetail-card{
    width:80vw;
    height:28rem;
    position: absolute;
    z-index: 1;
    pointer-events: none;
    background-color: transparent;
    display: none;
  }
  .productdetail-card-phone{
    display:block;
    width:80vw;
    height:28rem;
    position: absolute;
    z-index: 1;
    pointer-events: none;
    background-color: transparent;
  }
  
  .productdetail-card1,.productdetail-card2,.productdetail-card1-back,.productdetail-card2-back{
    /* width:50%; */
    width:80vw;
    height:28rem;
    border-radius: 10px;
    position: absolute;
    overflow: auto;
    
  }
  
  .ant-card-body{
    padding:0;
  }
  
  
  
  
  .productdetail-card1{
    background-color: var(--weverseBlack);
    border-style: solid;
    border-color:white;
    border-width:1px;
    margin-top: 1.5rem;
    margin-right: 0rem;
    pointer-events: all;
    display: flex;
    flex-direction: column;
    padding: 7% 5%;
  }
  
  .productdetail-card2{
    
    /* background-color: goldenrod; */
    background: linear-gradient(90deg, var(--weverseGreen) 20%, var(--weverseBlue) 84%);
    opacity: 40%;
    margin-left: 3rem;
    pointer-events: none;
  }
  .productdetail-card1-back{
    /* background-color: goldenrod; */
    background: linear-gradient(90deg, var(--weverseGreen) 20%, var(--weverseBlue) 84%);
    opacity: 40%;
    margin-top: 1.5rem;
    margin-right: 0rem;
    pointer-events: none;
  }
  
  .productdetail-card2-back{
    
    
    background-color: var(--weverseBlack);
    border-style: solid;
    border-color:white;
    border-width:1px;
    margin-left: 3rem;
    pointer-events: all;
    display: flex;
    flex-direction: column;
    padding: 7% 10%;
  }
  
  .productdetail-button-flip{
    margin:0.2rem 0rem;
    width:8rem;
    color:var(--weversePurple);
    cursor: pointer;
    margin-bottom: 1rem;
  }
  
  .productdetail-card1-left,.productdetail-card1-middle,.productdetail-card1-right{
    
    height:100%;
    width:100%;
    overflow: auto;
  }

  .productdetail-card2-left2{
    height:15rem;
  }
  
  .productdetail-card2-left{
    height:100%;
    width:60%;
    /* background-color: lightseagreen; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .productdetail-card2-right{
    height:100%;
    width:40%;
    /* background-color: blueviolet; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  
  .productdetail-card1-left{
    /* background-color: coral; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .productdetail-card1-middle{
    /* background-color: palevioletred; */
    display: flex;
    align-items: center;
    height:150rem;
  }
  .productdetail-card1-right{
    /* background-color: rebeccapurple; */
  }
  
  .productdetail-card1-right{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  
  .productdetail-card1-name{
    font-size: 14px;
    margin:0.5rem 0rem;
  }
  
  .productdetail-card1-price{
    font-size: 18px;
  }
  
  .productdetail-card1-type{
    font-size: 14px;
    margin:0.5rem 0rem;
  }
  .productdetail-card1-dis{
    font-size: 12px;
    margin:0.2rem 0rem;
  }
  
  .product-type-imagecon{
    display: flex;
    justify-content: space-between;
  }
  
  .product-type-image{
    width:30%;
    cursor: pointer;
    border-style:solid;
    border-width: 1px;
    border-radius: 10px;
    border-color:var(--weversePurple);
    background-color: rgba(158,144,242,0);
  }
  .product-type-image-opa{
    width:30%;
    cursor: pointer;
    border-style:solid;
    border-width: 1px;
    border-radius: 10px;
    border-color:var(--weversePurple);
    background-color: rgba(158,144,242,0.2);
  }
  
  .product-type-image:hover{
    background-color: rgba(158,144,242,0.2);
  }

  .productdetail-card1-select-con{
    display: flex;
    flex-direction: row;
  }
  
  
  
  .product-image{
    width:80%;
    margin: auto;
  }
  
  .card {
    width: 100%;
    height: 25rem;
    /* background-color: #84FAB0; */
    border-radius: 5px;background-size: cover;
    background-position: center center;
    will-change: transform;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card2 {
    width: 100%;
    height: 80%;
    will-change: transform;
    display: flex;
    justify-content: center;
  }
  
  .productdetail-card1-btn{
    background-color: var(--weversePurple);
    width:60%;
    margin-left: 40%;
    height:2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .productdetail-card2-info{
    height:70%;
    overflow: auto;
    margin-right: 0.5rem;
    /* background-color: #9E90F2; */
  }
  .productdetail-card2-left .productdetail-button-flip{
    margin:1rem 0rem 5rem 0rem;
    width:6rem;
    color:var(--weversePurple);
    cursor: pointer;
  }

  .productdetail-button-flip{
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;
  }
  
  .productdetail-card2-name{
    font-size:14px;
    margin-bottom:1rem;
  }
  
  .productdetail-card2-info-topic{
    font-size:14px;
    margin:0rem 0rem 0.5rem 0rem;
  }
  .productdetail-card2-info-text{
    font-size:12px;
    margin:0rem 0rem 1rem 0rem;
  }
}

.checkout-btn-con .ant-btn-primary{
  background-color: var(--weversePurple);
  border-radius: 10px;
}

