.header-wrap-right-sign{
    align-items: center;
}

.header-signup-btn{
    width:8rem;
    height:2rem;
    line-height: 2rem;
    color:white;
    background-color: var(--weversePurple);
    font-size: 16px;
    border-radius: 10px;
    margin-right: 3rem;
}
.header-signin-btn{
    width:8rem;
    height:2rem;
    line-height: 2rem;
    color:var(--weversePurple);
    border: 1px solid var(--weversePurple);
    font-size: 16px;
    border-radius: 10px;
}