.login-main-area{
    /* background-color: peru; */
    width:100vw;
    height: 93vh;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    border-bottom: 0.1rem solid white;
  }
.login-card{
    /* background-color: white; */
    text-align: center;
    border: 0.1rem solid white;
    border-radius: 20px;
    width:30rem;
    height:75%;
    --antd-wave-shadow-color: var(--weversePurple);
}

.login-logo{
    margin: 1rem 0rem;
    cursor: pointer;
    font-family: 'Nova Round', cursive;
    font-size: 36px;
    background: linear-gradient(90deg, var(--weverseGreen) 20%, var(--weverseBlue) 84%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.login-title{
    font-size: 24px;
    margin-bottom: 1rem;
}

.login-form-question-text{
    color: var(--weversePurple);
    text-decoration: underline;
    margin-bottom: 0;
}

.login-card .ant-input{
    background-color: var(--weverseBlack);
    color:white;
    font-family: 'Roboto Mono', monospace;
    width:80%;
    padding:10px 11px;
    border-radius: 10px;
    
}

.login-card .ant-input-affix-wrapper{
    background-color: var(--weverseBlack);
    width:80%;
    padding:10px 11px;
    border-radius: 10px;
}

.login-card .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon, .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon, .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon, .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon{
    margin-right: 0.5rem;
}

.login-card .ant-input-suffix{
    margin-left: 60px;
}

.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 0px;
}

.login-card .ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input:hover, .ant-form-item-has-error .ant-input-affix-wrapper:hover{
    background-color: var(--weverseBlack);

}

.login-card .ant-btn-primary {
    color: #fff;
    width:80%;
    padding: 0;
    border-radius: 10px;
    background: var(--weversePurple);
    border-color: var(--weversePurple);
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn{
    height:2.5rem;
    font-family: 'Roboto Mono', monospace;

}
.ant-form-item {
    margin-bottom: 1rem;
}
.login-form-question .ant-form-item {
    margin-bottom: 0rem;
}

.login-seperate::after, .login-seperate::before { 
    
     
    content: '';  
    display: inline-block;  
    width: 5.6rem;  
    height: 1px;  
    position: absolute;  
    background: #ccc;  
    margin-top: 10px;
   
    /* top: 50%;   */
}

.login-seperate{
    margin: 1.5rem 0rem;
}

.login-seperate::before{  
    margin-left: -10px;  
    transform: translateX(-100%);  
}  
.login-seperate::after{  
    margin-left: 10px;  
} 

.login-socialmedia-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.login-socialmedia-icon{
    width:3rem;
    /* background-color: white; */
    height:3rem;
    border-radius: 3rem;
    margin:0 1rem;
    cursor:  pointer;
}

.login-icon{
    width:3rem;
    height:3rem; 
}

.login-footer-container{
    display: flex;
    height:7vh;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px){
    .login-logo{
        margin: 1rem 0rem;
        cursor: pointer;
        font-family: 'Nova Round', cursive;
        font-size: 36px;
        background: linear-gradient(90deg, var(--weverseGreen) 20%, var(--weverseBlue) 84%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .login-card{
        /* background-color: white; */
        text-align: center;
        border: 0.1rem solid white;
        border-radius: 20px;
        width:25rem;
        height:75%;
        --antd-wave-shadow-color: var(--weversePurple);
    }

    .login-seperate::after, .login-seperate::before { 
    
     
        content: '';  
        display: inline-block;  
        width: 3rem;  
        height: 1px;  
        position: absolute;  
        background: #ccc;  
        margin-top: 10px;
       
        /* top: 50%;   */
    }
}